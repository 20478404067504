<template>
  <div class="user-edit">
    <v-user-management />
  </div>
</template>

<script>
import VUserManagement from "../../components/users/VUserManagement";

export default {
  name: "UserEdit",

  components: { VUserManagement },

  metaInfo() {
    return { title: this.$t("Users.EditUser") };
  },
};
</script>